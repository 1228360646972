.login-form .ant-form-item .ant-form-item-row .ant-form-item-label .ant-form-item-required::before {
    display: none; /* Hide the red asterisk */
}

.crm-logo-style {
    width: 80px; 
    height: 80px;
}

.contact-admin-text-style {
    color: #6f6f6f;
    font-weight: 500;
}

.login-to-account-text {
    font-size: 13px;
    color: #6f6f6f;
}

.welcome-to-crm-text {
    font-weight: bold;
    color: #262A2A;
}

.login-form .ant-form-item .ant-form-item-row .ant-form-item-label {
    padding: 0px;
    font-family: "Poppins";
    line-height: 1.4;
    padding-bottom: 2px;
}

.login-form .ant-form-item .ant-form-item-row .ant-form-item-label .ant-form-item-required {
    font-size: 13px;
}

.login-form .ant-form-item .ant-form-item-row .ant-form-item-label > label {
    font-size: 13px !important;
    color: #262A2A;
    font-weight: 500;
}

.login-form .ant-form-item .ant-form-item-explain {
    line-height: 1.1 !important;
}

.login-form .ant-form {
    font-family: "Poppins";
}

.login-form .ant-form-item {
    font-family: "Poppins";
    margin: 12px 0px;
}

.login-form .ant-form-item-explain-error {
    font-size: 12px;
    padding-top: 1px;
}

.login-form .ant-input {
    border-radius: 6px;
    background-color: white; /* Light grey background */
    font-family: "Poppins";
    font-size: 13px !important;
    height: 36px;
    &:hover,
    &:focus,
    &:active {
      .ant-input:not(.ant-input-disabled) {
        border-color: none;
      }
    }
  }
  
  .login-form .ant-input-affix-wrapper {
      border-radius: 6px;
      background-color: white; 
      font-family: "Poppins";
      padding: 0px 14px;
    &:hover,
    &:focus,
    &:active {
      .ant-input:not(.ant-input-disabled) {
        border-color: none;
      }
    }
  }

  .login-form .ant-form-item .ant-form-item-row{
    width: 100%;
  }

  .login-form .ant-btn {
    background-color: #e41f07 !important;
    border-color: #e41f07 !important;
    font-weight: 500 !important;
    height: 40px !important;
    &:hover,
    &:active,
    &:focus {
      background-color: #C23F11;
      border-color: #C23F11;
    }
  }
