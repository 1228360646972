.ant-form-item .ant-form-item-row .ant-form-item-label {
    padding: 0px;
    font-family: "Poppins";
    line-height: 1.3;
}

.ant-form-item .ant-form-item-row .ant-form-item-label .ant-form-item-required {
    font-size: 12px;
}

.ant-form-item .ant-form-item-row .ant-form-item-label > label {
    font-size: 11px !important;
}

.ant-form-item .ant-form-item-explain {
    line-height: 1.1 !important;
}

.ant-form {
    font-family: "Poppins";
}

.ant-form-item {
    font-family: "Poppins";
    margin: 12px 0px;
}

.ant-form-item-explain-error {
    font-size: 12px;
}