.th-mobile-sidebar .ant-drawer-header{
    padding: 8px !important;
}

/* .th-mobile-sidebar .ant-drawer .ant-drawer-body{
    padding: 0 !important;
} */

.th-mobile-sidebar .ant-drawer-wrapper-body .ant-drawer-body{
    padding: 0 !important;
}

.ant-layout .ant-layout-sider-trigger{
    display: none;
}

.ant-menu .ant-menu-item-selected {
    color: #004d76;
}

.sider-desktop-divider {
    margin: 1px; 
    border-color: white
}

.search-module .ant-select-selector{
    height: 28px !important;
    font-size: 12px !important;
}

.search-module .ant-select-selector .ant-select-selection-search-input {
    height: 24px !important;
}

.search-module .ant-select-selector .ant-select-selection-item {
    font-size: 12px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the fade-in animation to an element */
.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.3s; /* Duration of the animation */
  animation-timing-function: ease-in; /* Easing function */
  animation-fill-mode: forwards; /* Retain the end state of the animation */
}






