// Import mixins
@import '../mixins.scss';

.ant-btn {
    border-radius: 4px;
    font-size: 12px !important;
    font-family: "Poppins";
    border-color: $primary !important;
    color: $primary !important;
  &:hover,
  &:active,
  &:focus {
    background-color: lighten(#F0F0F0, 0%);
    border-color: lighten($primarydark, 0%);
    color: lighten($primarydark, 0%);
  }
  &-secondary {
    background: #ED6E42 !important;
    border-color: #ED6E42 !important;
    color: $white !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      background: lighten(#ED6E42, 0%) !important;
      border-color: lighten(#ED6E42, 0%) !important;
      color: $white;
    }
  }
  &-tertiary {
    color: $white !important;
    background: none;
    border: none;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      color: $white;
      background: none;
      border: none;
    }
  }
  &-primary {
    background: $primary !important;
    border-color: $primary !important;
    color: $white !important;
    font-weight: 500;
    &:hover,
    &:active,
    &:focus {
      background: lighten($primary, 0%) !important;
      border-color: lighten($primary, 0%) !important;
      color: $white;
    }
  }
  &-link {
    border-color: $primary !important;
    padding: 0px !important;
    &:hover,
    &:active,
    &:focus {
      border-color: lighten($primarydark, 0%) !important;
    }
  }
  &-danger {
    &:hover,
    &:active,
    &:focus {
      background: lighten($danger, 5%);
      border-color: lighten($danger, 5%);
      color: $white;
    }
  }
  &-primary,
  &-danger {
    &[disabled],
    &[disabled]:hover {
      background: $gray-2 !important;
      border-color: $gray-2 !important;
    }
  }
  &-text {
    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      border-color: none !important;
      border: none;
      color: $black;
    }
  }
}


