.content-wrapper {
    transition: padding-left 0.2s ease; /* Adjust the duration and easing as needed */
}
  
.content-wrapper-collapsed {
    transition: padding-left 0.2s ease; /* Adjust the duration and easing as needed */
}

.layout-style {
    background: #F6F6F6;
}

.content-style {
    min-height: 280px,
}