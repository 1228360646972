// Import mixins
@import '../mixins.scss';

.ant-input {
  border-radius: 6px;
  background-color: #F6F6F6; /* Light grey background */
  font-family: "Poppins";
  font-size: 13px !important;
  &:hover,
  &:focus,
  &:active {
    .ant-input:not(.ant-input-disabled) {
      border-color: $primary;
    }
  }
}

.ant-input-affix-wrapper {
    border-radius: 6px;
    background-color: #F6F6F6; 
    font-family: "Poppins";
  &:hover,
  &:focus,
  &:active {
    .ant-input:not(.ant-input-disabled) {
      border-color: $primary;
    }
  }
}
